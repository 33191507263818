import React, { Component } from "react";
import { QRCodeSVG } from "qrcode.react";
import urlConfig from "url.config";
import { userUtils } from "_helpers";
import config from "config";

class DeviceRegistrationSection extends Component {
  render() {
    return (
      <div className="tab-pane active show" id="MobileAddReg">
        <div className="card">
          <div className="card-body">
            <div className="">
              Please scan the QR code from Famstack Mobile App to start the
              mobile applicaiton registration
            </div>
            <div className="col-12 row">
              <div className="col-5 text-center pt-4">
                <QRCodeSVG
                  value={urlConfig.DEVICE_REGISTRATION_URL + "?emailId=" + userUtils.getCurrentUserEmail() + "&userId=" + userUtils.getCurrentUserId()}
                  title={"Famstack Mobile App Registration"}
                  size={250}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  minVersion={7}
                  marginSize={2}
                  imageSettings={{
                    src: "https://course5i.famstack.com/favicon.ico",
                    x: undefined,
                    y: undefined,
                    height: 24,
                    width: 24,
                    opacity: 1,
                    excavate: true,
                  }}
                />
                <a className="d-none" href={config.BACK_END_API_ENDPOINT  + urlConfig.DEVICE_REGISTRATION_URL + "?emailId=" + userUtils.getCurrentUserEmail() + "&userId=" + userUtils.getCurrentUserId()}>Link</a>
              </div>
              <div className="col-7">
                <div className="banner-text m-4">
                  <h4>Download Famstack Mobile Application</h4>
                  <p></p>
                  <div className="banner-btn">
                    <a href="#">
                      <img src="/assets/images/custom/apple-store-btn.png" />
                    </a>{" "}
                    <a href="#">
                      <img src="/assets/images/custom/google-store-btn.png" />
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceRegistrationSection;
