import { userUtils } from "_helpers";
import { accessUtils } from "_helpers";

import React, { Component } from "react";
import DeviceRegistrationSection from "./components/fragments/DeviceRegistrationSection";
import ScheduleListSection from "./components/fragments/ScheduleListSection";

class ProfileSettingsPage extends Component {
  constructor() {
    super();
    this.state = {
       permission: accessUtils.getProfileSettingsControl()
    }
  }

  isDeviceRegistrationEnabled = () => {
    var user = userUtils.getCurrentUser();
    return user && user.deviceAppEnabled === true;
  }

  componentDidMount() {
    this.props.pageHeaderNameChangeHandler(this.props.routeProp.name)
    
  }
  render() {
    return (
      <div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-lg-flex justify-content-between">
              <ul className="nav nav-tabs page-header-tab">
                {this.state.permission.deviceRegistrationEnabled === true &&
                  <li className="nav-item"><a className="nav-link active show p-1" data-toggle="tab" href="#MobileAddReg">Moble App Registration</a></li>
                }
                {this.state.permission.schedulerViewEnabled === true &&
                  <li className="nav-item"><a className="nav-link  p-1" data-toggle="tab" href="#scheduleListView">Schedulers</a></li>
                }
                {this.state.permission.serviceHealthEnabled === true &&
                  <li className="nav-item"><a className="nav-link p-1" data-toggle="tab" href="#serviceHealthView">Service Health</a></li>
                }
                {this.state.permission.companySettingsEnabled === true &&
                  <li className="nav-item"><a className="nav-link  p-1" data-toggle="tab" href="#Company_Settings">Company</a></li>
                }
                {this.state.permission.localizationSettingsEnabled === true &&
                  <li className="nav-item"><a className="nav-lin  p-1" data-toggle="tab" href="#Localization">Localization</a></li>
                }
                {this.state.permission.notificationSettingsEnabled === true &&
                  <li className="nav-item"><a className="nav-link  p-1" data-toggle="tab" href="#Notifications">Notifications </a></li>
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-md-12">
                <div className="tab-content">
                {this.state.permission.deviceRegistrationEnabled === true && this.isDeviceRegistrationEnabled() === true &&
                    <DeviceRegistrationSection />
                  }
                {this.state.permission.schedulerViewEnabled === true &&
                    <div className="tab-pane" id="scheduleListView">
                      <ScheduleListSection/>
                    </div>
                  }
                   {this.state.permission.serviceHealthEnabled === true &&
                    <div className="tab-pane" id="serviceHealthView">
                      <div className="col-12 row">
                      <div className="col-6">
                      <div className="card pl-3">
                        <div className="card-body">
                        <div className="">
                          <h6 className="font-14 font-weight-bold mt-1 text-muted">Server Status</h6>
                            <ul className="setting-list list-unstyled mt-1 setting_switch">
                              <li>
                            <label>
                              <i className="fa fa-lg fa-times-circle colorred"></i>
                              <span className="ml-3">Application Server</span>
                                </label>
                              </li>
                              <li>
                            <label>
                              <i className="fa fa-lg fa-check-circle colorgreen"></i>
                              <span className="ml-3">Reporting Server</span>
                            </label>
                          </li>
                          <li>
                            <label>
                              <i className="fa fa-lg fa-check-circle colorgreen"></i>
                              <span className="ml-3">Scheduler Server</span>
                        </label>
                        </li>
                          </ul>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div className="col-6">
                      <div className="card pl-3">
                        <div className="card-body">
                        <div className="">
                          <h6 className="font-14 font-weight-bold mt-1 text-muted">Integration Service Status</h6>
                            <ul className="setting-list list-unstyled mt-1 setting_switch">
                              <li>
                            <label>
                              <i className="fa fa-lg fa-times-circle colorred"></i>
                              <span className="ml-3">Salesforce Service</span> <span className="colorred">(Password expired!)</span>
                              </label>
                              </li>
                              <li>
                            <label>
                              <i className="fa fa-lg fa-check-circle colorgreen"></i>
                              <span className="ml-3">Email Service</span>
                            </label>
                          </li>
                          <li>
                            <label>
                              <i className="fa fa-lg fa-check-circle colorgreen"></i>
                              <span className="ml-3">ZingHR Health</span>
                        </label>
                        </li>
                          </ul>
                        </div>
                        </div>
                        </div>
                        </div>
                      </div>
                      </div>
                  }
                  {this.state.permission.companySettingsEnabled === true &&
                    <div className="tab-pane" id="Company_Settings">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Company Settings</h3>
                        </div>
                        <div className="card-body">
                          <form>
                            <div className="row">
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <label>Company Name <span className="text-danger">*</span></label>
                                  <input disabled className="form-control" type="text" defaultValue="Course5i Intelligence Pvt Ltd." />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <label>Contact Person</label>
                                  <input disabled className="form-control" defaultValue="Sebastian" type="text" />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <label>Mobile Number <span className="text-danger">*</span></label>
                                  <input disabled className="form-control" defaultValue="xxx-xxx-xxxx" type="text" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                  <label>Country</label>
                                  <select disabled className="form-control">
                                    <option value>-- Select Country --</option>
                                  
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                  <label>State/Province</label>
                                  <select disabled className="form-control">
                                    <option selected>Karnataka</option>
                                    <option>Alaska</option>
                                    <option>Alabama</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                  <label>City</label>
                                  <input disabled className="form-control" defaultValue="Bangalore" type="text" />
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-3">
                                <div className="form-group">
                                  <label>Postal Code</label>
                                  <input disabled className="form-control" defaultValue={91403} type="text" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Phone Number</label>
                                  <input disabled className="form-control" defaultValue="xxx-xxx-xxxx" type="text" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 text-right m-t-20">
                                <button disabled type="button" className="btn btn-primary">SAVE</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  }
                  {this.state.permission.localizationSettingsEnabled === true &&
                    <div className="tab-pane" id="Localization">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Basic Settings</h3>
                        </div>
                        <div className="card-body">
                          <form>
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Default Country</label>
                                  <select disabled className="form-control">
                                    <option>USA</option>
                                    <option>United Kingdom</option>
                                    <option selected="selected">India</option>
                                    <option>French</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Date Format</label>
                                  <select disabled className="form-control">
                                    <option value="d/m/Y">15/05/2016</option>
                                    <option value="d.m.Y">15.05.2016</option>
                                    <option value="d-m-Y">15-05-2016</option>
                                    <option selected="selected" value="m/d/Y">05/15/2016</option>
                                    <option value="Y/m/d">2016/05/15</option>
                                    <option value="Y-m-d">2016-05-15</option>
                                    <option value="M d Y">May 15 2016</option>
                                    <option value="d M Y">15 May 2016</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Timezone</label>
                                  <select disabled className="form-control">
                                    <option>10:45am Chennai (IST +5:30)</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Default Language</label>
                                  <select disabled className="form-control">
                                    <option selected="selected">English</option>
                                    <option>Russian</option>
                                    <option>Arabic</option>
                                    <option>French</option>
                                    <option>Hindi</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Currency Code</label>
                                  <select disabled className="form-control">
                                    <option selected="selected">USD</option>
                                    <option>Pound</option>
                                    <option>EURO</option>
                                    <option>Ringgit</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Currency Symbol</label>
                                  <input disabled className="form-control" defaultValue="$" type="text" />
                                </div>
                              </div>
                              <div className="col-sm-12 text-right m-t-20">
                                <button disabled type="button" className="btn btn-primary">Save</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  }
                  {this.state.permission.notificationSettingsEnabled === true &&
                    <div className="tab-pane" id="Notifications">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">Notifications Settings</h3>
                        </div>
                        <div className="card-body  d-none">
                          <ul className="list-group">
                            <li className="list-group-item">
                              Anyone send me a message
                              <div className="float-right">
                                <label className="custom-control custom-checkbox">
                                  <input disabled type="checkbox" className="custom-control-input" />
                                  <span className="custom-control-label">&nbsp;</span>
                                </label>
                              </div>
                            </li>
                            <li className="list-group-item">
                              Anyone seeing my profile page
                              <div className="float-right">
                                <label className="custom-control custom-checkbox">
                                  <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                  <span className="custom-control-label">&nbsp;</span>
                                </label>
                              </div>
                            </li>
                            <li className="list-group-item">
                              Anyone posts a comment on my post
                              <div className="float-right">
                                <label className="custom-control custom-checkbox">
                                  <input disabled type="checkbox" className="custom-control-input" defaultChecked />
                                  <span className="custom-control-label">&nbsp;</span>
                                </label>
                              </div>
                            </li>
                            <li className="list-group-item">
                              Anyone send me a message
                              <div className="float-right">
                                <label className="custom-control custom-checkbox">
                                  <input disabled type="checkbox" className="custom-control-input" />
                                  <span className="custom-control-label">&nbsp;</span>
                                </label>
                              </div>
                            </li>
                            <li className="list-group-item">
                              Anyone seeing my profile page
                              <div className="float-right">
                                <label className="custom-control custom-checkbox">
                                  <input disabled type="checkbox" className="custom-control-input" />
                                  <span className="custom-control-label">&nbsp;</span>
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileSettingsPage;
