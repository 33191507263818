import React, { Component } from "react";

class LongTextInput extends Component {
  onChange = (value) => {
    this.props.onChange({ ...this.props.questionConfig, answer: value });
  };
  render() {
    return (
      <>
        {this.props.questionConfig && (
          <div className="col-md-12">
            <div className="inputField">
              <label className="labelTxt">
                {this.props.questionConfig.question}
                {this.props.questionConfig.mandatory &&
                  this.props.questionConfig.mandatory === true}
                <span className="colorred">*</span>
              </label>
              <textarea
                className="textField"
                name="message"
                id="message"
                col="50"
                placeholder=""
                disabled={this.props.readonly}
                value={this.props.questionConfig.answer || ""}
                onChange={(event) => this.onChange(event.target.value)}
              ></textarea>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default LongTextInput;
