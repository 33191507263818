import React, { Component } from "react";
import "views/common/plugins/feedback/style.css";
import OverAllRating from "./components/feedbackrequest/OverAllRatingInput";
import TextInput from "./components/feedbackrequest/TextInput";
import DecisionOptionSelectInput from "./components/feedbackrequest/DecisionOptionSelectInput";
import MultiOptionDropDownSingleSelectInput from "./components/feedbackrequest/MultiOptionDropDownSingleSelectInput";
import MultiOptionMultiSelectInput from "./components/feedbackrequest/MultiOptionMultiSelectInput";
import MultiOptionSingleSelectInput from "./components/feedbackrequest/MultiOptionSingleSelectInput";
import LongTextInput from "./components/feedbackrequest/LongTextInput";
import ReactionInput from "./components/feedbackrequest/ReactionInput";
import MultiStarsSelectInput from "./components/feedbackrequest/MultiStarsSelectInput";
import SingleDecisionOptionSingleSelectInput from "./components/feedbackrequest/SingleDecisionOptionSingleSelectInput";
import { store } from "_helpers";
import { feedbackRequestActions } from "_actions";
import { feedbackTemplateActions } from "_actions";
import { connect } from "react-redux";
import SingleStartsSelectInput from "./components/feedbackrequest/SingleStarsSelectInput";
import { commonUtils } from "_helpers";

class FeedbackRequestPage extends Component {
  constructor() {
    super();
    this.state = {
      feedbackRequestAnswers: null,
      feedbackData: null,
      hasError: false,
      formError: false,
      isSubmitFailed: false,
      requestSubmited: false,
    };
  }
  componentDidMount() {
    document.title = "Project Feedback Request form";
    let feedbackTemplateId = this.props.match.params.templateId;
    let feedbackRequestId = this.props.match.params.feedbackRequestId;
    let feedbackId = this.props.match.params.feedbackId;

    console.log("feedbackRequestId - " + feedbackRequestId);
    console.log("feedbackTemplateId - " + feedbackTemplateId);
    console.log("feedbackId - " + feedbackId);

    this.setState({
      feedbackTemplateId,
      feedbackRequestId,
      feedbackId,
      feedbackData: null,
    });
    if (feedbackTemplateId) {
      this.getFeedbackDataFromTemplateRequest(feedbackTemplateId);
    }
    if (feedbackRequestId) {
      this.getFeedbackDataFromFeedbackRequest(feedbackRequestId);
    }
    if (feedbackId) {
      this.getFeedbackDataFromFeedback(feedbackId);
    }
  }

  getFeedbackDataFromTemplateRequest = (feedbackTemplateId) => {
    store.dispatch(
      this.props.getFeedbackTemplate(
        feedbackTemplateId,
        this.mapFeedbackDataFromTemplate
      )
    );
  };

  getFeedbackDataFromFeedback = (feedbackId) => {
    store.dispatch(
      this.props.getFeedback(feedbackId, this.mapFeedbackDataFromFeedback)
    );
  };

  getFeedbackDataFromFeedbackRequest = (feedbackRequestId) => {
    store.dispatch(
      this.props.getFeedbackRequest(
        feedbackRequestId,
        this.mapFeedbackDataFromFeedbackRequest
      )
    );
  };

  mapFeedbackDataFromTemplate = (templateResponse) => {
    this.setState({
      feedbackData: templateResponse,
      itemId: "000001",
      itemName: "Sample Project Name",
      viewMode: "TEMPLATE",
    });
  };
  mapFeedbackDataFromFeedback = (feedbackResponse) => {
    this.setState({
      feedbackData: {
        ...feedbackResponse.feedbackTemplateInfo,
        itemId: feedbackResponse.itemId,
        itemName: feedbackResponse.itemName,
      },
      viewMode: "TEMPLATE",
    });
  };

  mapFeedbackRequestAndTemplateQuestionDataList = (
    feedbackDataList,
    questionDataList
  ) => {
    return questionDataList.map((item) => {
      const matchedItem = feedbackDataList.find((obj) => obj.id === item.id);
      if (matchedItem) {
        return { ...item, answer: matchedItem.answer };
      }
      return item;
    });
  };

  mapFeedbackDataFromFeedbackRequest = (feedbackRequestResponse) => {
    if (
      feedbackRequestResponse.hasOwnProperty("error") ||
      !feedbackRequestResponse.feedbackInfo
    ) {
      this.setState({
        hasError: true,
      });
    } else {
      var questionDataList = feedbackRequestResponse.feedbackDataList
        ? this.mapFeedbackRequestAndTemplateQuestionDataList(
            feedbackRequestResponse.feedbackDataList,
            feedbackRequestResponse.feedbackTemplateInfo.questionDataList
          )
        : feedbackRequestResponse.feedbackTemplateInfo.questionDataList;
      this.setState({
        feedbackData: {
          ...feedbackRequestResponse,
          itemId: feedbackRequestResponse.feedbackInfo.itemId,
          itemName: feedbackRequestResponse.feedbackInfo.itemName,
          questionDataList: questionDataList,
        },
        viewMode: "REQUESTED",
      });
    }
  };

  isReadOnlyMode = () => {
    return (
      this.state.feedbackData &&
      this.state.viewMode === "REQUESTED" &&
      this.state.feedbackData.status !== "REQUESTED"
    );
  };
  isTemplateMode = () => {
    return this.state.viewMode === "TEMPLATE";
  };

  onChange = (feedbackAnswer) => {
    var newFeedbackQuestionItems = [
      ...this.state.feedbackData.questionDataList,
    ];
    newFeedbackQuestionItems[feedbackAnswer.index] = {
      ...newFeedbackQuestionItems[feedbackAnswer.index],
      ...feedbackAnswer,
    };
    this.setState({
      feedbackData: {
        ...this.state.feedbackData,
        questionDataList: newFeedbackQuestionItems,
      },
    });
  };

  isValid = (questionDataList) => {
    questionDataList &&
      questionDataList.map((question) => {
        if (
          question.mandatory === true &&
          (!question.answer || question.answer === "")
        ) {
          return false;
        }
      });
    return true;
  };

  submitCallBack = (feedbackRequestResponse) => {
    if (feedbackRequestResponse.hasOwnProperty("error")) {
      this.setState({
        isSubmitFailed: true,
      });
    } else {
      this.setState({
        requestSubmited: true,
        feedbackData: {
          ...this.state.feedbackData,
          status: "RECEIVED",
        },
      });
    }
  };

  resetPage = () => {
    var questionDataList =
      this.state.feedbackData.feedbackTemplateInfo.questionDataList;
    this.setState({
      formError: false,
      isSubmitFailed: false,
      feedbackData: {
        ...this.state.feedbackData,
        questionDataList: questionDataList,
      },
    });
  };

  submitFeedbackRequestAnswers = () => {
    const isValidForm = this.isValid(this.state.feedbackData.questionDataList);
    if (isValidForm) {
      var feedbackRequestRequest = {
        id: this.state.feedbackData.id,
        feedbackDataList: this.state.feedbackData.questionDataList.map(
          (questionConfig) => {
            return {
              id: questionConfig.id,
              question: questionConfig.question,
              answer: questionConfig.answer,
            };
          }
        ),
      };
      store.dispatch(
        this.props.updateFeedbackRequest(
          feedbackRequestRequest,
          this.submitCallBack
        )
      );
    }
    this.setState({
      formError: !isValidForm,
    });
  };

  isFeedbackSubmited = () => {
    return (
      this.state.feedbackData &&
      this.state.feedbackData.status &&
      (this.state.feedbackData.status === "RECEIVED" ||
        this.state.feedbackData.status === "REVIEW" ||
        this.state.feedbackData.status === "CLOSED")
    );
  };

  render() {
    return (
      <main className="overflow-hidden">
        <div className="container">
          <div className="wrapper">
            <div className="row">
              <div className="col-md-12 tab-100 fadeInleft">
                <div className="row header">
                  <img
                    src="/assets/images/custom/c5i-logo.png"
                    className="headerLogo"
                  />
                  <img src="/favicon.ico" className="fsheaderLogo" />
                </div>
                {!this.state.feedbackData && this.state.hasError === false && (
                  <b>Loading.....</b>
                )}
                {this.state.hasError === true && (
                  <span className="fsize18 fweight600 m-5 d-flex">
                    The feedback link you followed is either incorrect or has
                    expired. Please check the URL or try again later.
                  </span>
                )}
                {this.isFeedbackSubmited() && !this.state.requestSubmited && (
                  <span className="fsize16 fweight600 d-flex colorgreen m-3">
                    Feedback has been submitted by
                    <span className="colorblack pl-2">
                      {this.state.feedbackData.personName +
                        " (" +
                        this.state.feedbackData.personEmail +
                        ")"}
                    </span>
                  </span>
                )}
                {this.state.feedbackData && (
                  <div className="product_review">
                    <div className="product_review_inner">
                      <div className="row">
                        <div className="col-md-12 mb-2 card">
                          {this.state.viewMode === "TEMPLATE" && (
                            <div className="inactive top200px colorblue">
                              TEMPLATE
                            </div>
                          )}
                          <h6>
                            <span>
                              Please rate your experience working with C5i based
                              on the following aspects:
                            </span>
                          </h6>
                          <h6>
                            <span>
                              Project Name:
                              <b>{this.state.feedbackData.itemName}</b>
                            </span>
                          </h6>
                          <h6>
                            <span>
                              Project No:
                              <b>{this.state.feedbackData.itemId}</b>
                            </span>
                          </h6>
                        </div>
                      </div>
                      {this.state.feedbackData &&
                        this.state.feedbackData.questionDataList &&
                        this.state.feedbackData.questionDataList
                          .filter(
                            (questionConfig) =>
                              questionConfig.type === "overallstarrating"
                          )
                          .map((questionConfig) => (
                            <>
                              <OverAllRating
                                key={questionConfig.id}
                                questionConfig={questionConfig}
                                onChange={this.onChange}
                                readonly={this.isReadOnlyMode()}
                              />

                              <h4 className="num-heading">
                                Thank you for sharing your feedback!
                              </h4>
                            </>
                          ))}
                      {this.state.feedbackData &&
                        this.state.feedbackData.questionDataList &&
                        this.state.feedbackData.questionDataList.length > 0 && (
                          <h6>
                            Time permitting, may we request you to please share
                            your experience on the following parameters:
                          </h6>
                        )}
                      <div className="row mt-4">
                        {this.state.feedbackData &&
                          this.state.feedbackData.questionDataList &&
                          commonUtils
                            .sortArrayOfObjectsByKey(
                              this.state.feedbackData.questionDataList,
                              "index",
                              true
                            )
                            .map((questionConfig) => {
                              return questionConfig.type === "boolean" ? (
                                <DecisionOptionSelectInput
                                  key={questionConfig.id}
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : questionConfig.type === "string" ? (
                                <TextInput
                                  key={questionConfig.id}
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : questionConfig.type === "number" ? (
                                <TextInput
                                  key={questionConfig.id}
                                  type="number"
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : questionConfig.type === "longstring" ? (
                                <LongTextInput
                                  key={questionConfig.id}
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : questionConfig.type ===
                                "multiopitonrating" ? (
                                <MultiStarsSelectInput
                                  key={questionConfig.id}
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : questionConfig.type === "dropdown" ? (
                                <MultiOptionDropDownSingleSelectInput
                                  key={questionConfig.id}
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : questionConfig.type === "starrating" ? (
                                <SingleStartsSelectInput
                                  key={questionConfig.id}
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : questionConfig.type === "multiselect" ? (
                                <MultiOptionMultiSelectInput
                                  key={questionConfig.id}
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : questionConfig.type ===
                                "singleselectoption" ? (
                                <SingleDecisionOptionSingleSelectInput
                                  key={questionConfig.id}
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : questionConfig.type ===
                                "singleselectmultioptions" ? (
                                <MultiOptionSingleSelectInput
                                  key={questionConfig.id}
                                  questionConfig={questionConfig}
                                  onChange={this.onChange}
                                  readonly={this.isReadOnlyMode()}
                                />
                              ) : (
                                <></>
                              );
                            })}
                      </div>
                      {this.state.feedbackData &&
                        this.state.feedbackData.questionDataList &&
                        this.state.feedbackData.questionDataList
                          .filter(
                            (questionConfig) =>
                              questionConfig.type === "reaction"
                          )
                          .map((questionConfig) => (
                            <ReactionInput
                              key={questionConfig.id}
                              questionConfig={questionConfig}
                              onChange={this.onChange}
                              readonly={this.isReadOnlyMode()}
                            />
                          ))}
                      {this.state.requestSubmited && (
                        <span className="fsize16 fweight600 d-flex colorgreen">
                          Your feedback has been submitted successfully! You may
                          now close this page.
                        </span>
                      )}
                      {!this.isReadOnlyMode() && !this.isTemplateMode() && (
                        <>
                          {this.state.formError && (
                            <span className="fsize16 fweight600 d-flex colorred">
                              Please Complete the mandatory fields before
                              submitting.
                            </span>
                          )}
                          {this.state.isSubmitFailed && (
                            <span className="fsize16 fweight600 d-flex colorred">
                              Feedback submit failed! Please Try again later.
                            </span>
                          )}

                          <div className="input_field">
                            <label>Addtional Options</label>
                            <div className="row">
                              <div className="radio-toggle">
                                <div className="toggle-onOff active">
                                  <div className="toggle-btn"></div>
                                </div>
                                <span>Send me a copy of the Response</span>
                              </div>
                            </div>
                          </div>
                          <div className="review_btn">
                            <button
                              type="button"
                              onClick={() => this.resetPage()}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              id="submit"
                              className="submit"
                              onClick={() =>
                                this.submitFeedbackRequestAnswers()
                              }
                            >
                              Give Feedback
                              <img
                                src="/assets/images/custom/emoji/thumb.gif"
                                alt="thumb"
                              />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  isLoading: true,
});
const mapDispatchToProps = (dispatch) => ({
  getFeedbackRequest: feedbackRequestActions.getFeedbackRequest,
  getFeedback: feedbackRequestActions.getFeedback,
  getFeedbackTemplate: feedbackTemplateActions.getFeedbackTemplate,
  updateFeedbackRequest: feedbackRequestActions.updateFeedbackRequest,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackRequestPage);
