import { scheduleActions } from "_actions";
import { employeeActions } from "_actions";
import { appConfigActions } from "_actions";
import { store } from "_helpers";
import classNames from "classnames/bind";
import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";

class ScheduleListSection extends Component {
  componentDidMount() {
    store.dispatch(this.props.getAllScheulers());
    store.dispatch(this.props.getAllEmployees());
    store.dispatch(this.props.getAllTeams());
  }

  render() {
    return (
      <div>
        <div className="table-responsive p-2">
          {this.props.isLoading && (
            <table className="table table-hover table-vcenter table-striped">
              <tbody>
                <tr>
                  <td colSpan="12">
                    <Skeleton count={7} height={40} />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {!this.props.isLoading &&
            this.props.allUserTeams &&
            this.props.allEmployees &&
            this.props.items &&
            this.props.items.map((item, index) => (
              <ScheduleItem
                key={item.id}
                item={item}
                enableSchedule={this.props.enableSchedule}
                removeSchedule={this.props.removeSchedule}
                allUserTeams={this.props.allUserTeams}
                allEmployees={this.props.allEmployees}
              />
            ))}
        </div>
      </div>
    );
  }
}

class ScheduleItem extends Component {
  constructor() {
    super();
    this.state = {
      confrimDelete: false,
    };
  }

  handleEnable = (isEnabled) => {
    if (this.props.item.deleted === true) {
      return;
    }
    store.dispatch(
      this.props.enableSchedule({ itemId: this.props.item.id, isEnabled })
    );
  };

  getUserTeamNames = (userTeamIds) => {
    return (
      this.props.allUserTeams &&
      this.props.allUserTeams
        .filter((userTeam) => userTeamIds.includes(userTeam.id))
        .map((userTeam) => userTeam.name)
    );
  };

  geEmployeeNameName = (employeeId) => {
    return (
      this.props.allEmployees &&
      this.props.allEmployees
        .filter((employee) => employeeId === employee.id)
        .map((employee) => employee.name)
    );
  };

  handleRemove = (isConfrimDelete) => {
    if (isConfrimDelete) {
      store.dispatch(this.props.removeSchedule({ itemId: this.props.item.id }));
    } else {
      this.setState({
        confrimDelete: true,
      });
    }
  };
  render() {
    return (
      <div className={`taskActivityContainer`}>
        <div className="d-flex justify-content-between align-items-center mt-0 py-0 mb-1 px-2 shadow-sm bg-white rounded">
          <div
            className={classNames({
              "text-strike colorred": this.props.item.deleted === true,
            })}
          >
            <i
              className={classNames(
                "fa-lg",
                {
                  "fa fa-check-circle colorgreen":
                    this.props.item.enabled === true,
                },
                {
                  "fa fa-minus-circle colorred":
                    this.props.item.enabled === false,
                }
              )}
            ></i>
            <span className="mx-1 fweight600 fsize13 colorblue">
              {this.props.item.id}
            </span>

            <span
              title={this.props.item.name}
              className={`fsize14 mx-1 d-inline-block text-truncate mt-0 align-middle w-300`}
            >
              {this.props.item.name}
            </span>
            <span className="mx-1 fweight600 fsize13 colorblue">
            <i class="fa fa-history colorgreen pr-1"></i> {this.props.item.lastRun}
            </span>
            <span className="mx-1 fweight600 fsize13 colorgreen">
            <i class="fa fa-spinner fa-spin colororange pr-1"></i>{this.props.item.nextRun}
            </span>
            <span className="mx-1 fweight600 fsize13">
              {this.props.item.scheduleType}
            </span>
          </div>
          <div>
            <span className="float-right">
              <span className="mt-1 ml-4">
                <span className="fsize14 mx-1">
                  {this.props.item.cronString || ""}
                </span>
                <span>
                  <label className=" custom-switch custom-switch-sm m-0">
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      onClick={(e) =>
                        this.handleEnable(!this.props.item.enabled)
                      }
                      checked={this.props.item.enabled ? true : false}
                      className="custom-switch-input"
                    />
                    <span className="custom-switch-indicator" />
                  </label>
                </span>
                <button
                  type="button"
                  onClick={(e) => this.handleRemove(this.state.confrimDelete)}
                  className="link pl-2"
                  title="Remove"
                >
                  {this.state.confrimDelete === true && (
                    <i className="fe fe-check colorred blink_text"></i>
                  )}
                  {this.state.confrimDelete === false && (
                    <i className="fe fe-x colorred"></i>
                  )}
                </button>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  items: state.schedules.items,
  allEmployees: state.employees.allEmployees,
  allUserTeams: state.appconfig.allTeams,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTeams: appConfigActions.getAllTeams,
  getAllEmployees: employeeActions.getAllEmployees,
  getAllScheulers: scheduleActions.getAllSchedules,
  enableSchedule: scheduleActions.enableSchedule,
  removeSchedule: scheduleActions.removeSchedule,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleListSection);
