import React, { Component } from "react";

import { validationUtils } from '_helpers'
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { commonUtils } from '_helpers'
import SidebarFooter from "views/project/components/modal/SidebarFooter";
import { store } from "_helpers";
import workItemUtils from "_helpers/workitem.utils";
class CreateEmployeeSidebarSection extends Component {
  constructor() {
    super();
    this.state = {
      formErrors: {},
      hasFormError: false,
      enabledExitDateUpdateButton: false
    }
  }

  componentDidMount() {
    this.initializeRequest();
  }
  onResetFormError = () => {
    this.setState({
      hasFormError: false
    })
  }

  initializeRequest = () => {
    let userTeamId = commonUtils.getCurrentUserTeamId();
    let employeeRequest = this.props.editMode || this.props.readOnly ? { ...this.props.selectedEmployeeInfo } : { userTeamId: userTeamId }
    this.setState({
      employeeRequest: employeeRequest
    });
  }

  onFormError = (fieldName, hasError) => {
    this.setState(prevState => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError
      }
    }))
  }

  onChange = (fieldName, value) => {
    this.onResetFormError()
    if (fieldName === 'userTeamId') {
      store.dispatch(this.props.getModuleConfig(value));
      store.dispatch(this.props.getProjectTeamAccounts(value));
    }

    this.setState(prevState => ({
      employeeRequest: {
        ...prevState.employeeRequest,
        [fieldName]: value
      }
    }))

    if (fieldName === 'exitDate') {
      this.setState({
        enabledExitDateUpdateButton: true
      })
    }
  }

  onEffectiveDateDateChange = (selectedDate) => {
    this.onChange("effectiveDate", selectedDate);
  }
  handleSubmit = (isCreateAnotherEnabled) => {

    let isValid = validationUtils.isValidRequest(this.props.fieldConfig, this.state.employeeRequest, this.state.formErrors);
    //console.log("Validating form  request, isValid - " + isValid);
    //console.log(this.state.employeeRequest);
    //console.log(this.state.formErrors)
    if (isValid) {
      this.props.handleSubmit(this.state.employeeRequest, this.props.editMode, isCreateAnotherEnabled, !this.props.editMode ? this.initializeRequest : null);
    } else {
      this.setState({
        hasFormError: true
      })
    }
  }
  handleUpdateUserExitDate = () => {
    this.props.handleUpdateUserExitDate(this.state.employeeRequest.id, this.state.employeeRequest.exitDate);
  }

  render() {
    return (
      <>
        {this.state.employeeRequest &&
          <>
            <div className="card-body pl-3 pr-3 overflow-auto">
              <div className="row">
                <div className="card pb-0 mb-0">
                  <div className="card-header pt-0 pb-1">
                    <span className="text-color-gray-aaa"><i className="fa fa-info-circle pr-1"></i>Basic details</span>
                    <div className="card-options">
                      <button className="link card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></button>
                    </div>
                  </div>
                  <div className="col-12 row">
                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <div className="col-8">
                          <CustomInput readOnly={this.props.readOnly} inputtype='text'
                            label="Employee Name"
                            name="name"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Employee Name..."
                            datatype='alpnumspace'
                          />
                      </div>
                      <div className="col-4">
                          <CustomInput
                            readOnly={this.props.readOnly}
                            type='radios'
                            label="Device Login Enabled?"
                            name="deviceAppEnabled"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Device Login Disabled..."
                            onFormError={this.onFormError}

                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <CustomInput
                            readOnly={this.props.readOnly}
                            inputtype='email'
                            label="Email"
                            name="email"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Email..."
                            datatype='email' />
                        </div>
                        <div className="col-4">
                          <CustomInput
                            readOnly={this.props.readOnly}
                            type='radios'
                            label="App Login Disabled?"
                            name="appLoginDisabled"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="App Login Disabled..."
                            onFormError={this.onFormError}

                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Location"
                            name="location"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Location..."
                            isAppConfig={true}
                            createConfig={this.props.createConfig}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='dateselect'
                            label="Date of Birth"
                            name="dateOfBirth"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            time={false}
                            onFormError={this.onFormError}
                            minDate={new Date(1960, 0, 1)}
                            maxDate={new Date(2000, 0, 1)}
                            currentDate={new Date(1980, 0, 1)}
                          />

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Qualification"
                            name="qualification"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Qualification..."
                            isAppConfig={true} createConfig={this.props.createConfig}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Designation"
                            name="designation"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Designation..."
                            isAppConfig={true} createConfig={this.props.createConfig}
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="col-12 col-lg-6">
                      {this.props.isPageView && this.props.permission && this.props.permission.updateUserExitDateEnabled &&
                        <div className="row borderRed">
                          <div className="col-6">
                            <CustomInput
                              className="colorRed"
                              type='dateselect'
                              label="Exit Date"
                              name="exitDate"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.employeeRequest}
                              onChange={this.onChange}
                              time={false}
                              onFormError={this.onFormError}
                              minDate={new Date(2022, 0, 1)}
                              maxDate={new Date(2050, 0, 1)}
                              currentDate={new Date()}
                            />
                          </div>
                          <div>
                            <button disabled={!this.state.enabledExitDateUpdateButton} className="btn btn-primary btn-sm mr-3 buttonRed" onClick={() => this.handleUpdateUserExitDate()}>Update Exit Date</button>
                          </div>
                        </div>
                      }
                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly} inputtype='text' label="Employee Code" name="empCode" fieldConfig={this.props.fieldConfig} mappingObject={this.state.employeeRequest} onChange={this.onChange} onFormError={this.onFormError} placeholder="Employee Code..." datatype='alpnum' />
                        </div>
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Employee Type"
                            name="empType"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Employee Type..."
                            isAppConfig={true}
                            createConfig={this.props.createConfig}
                            configName={"empType"}
                            onFormError={this.onFormError}
                          />
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Gender"
                            name="gender"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Gender"
                            options={[{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }]}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly} inputtype='text'
                            label="Mobile Number"
                            name="mobileNumber"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Mobile Number..." datatype='num' />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='dateselect'
                            label="Date of Joining"
                            name="dateOfJoin"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            time={false}
                            onFormError={this.onFormError}
                            minDate={new Date(1980, 0, 1)}
                            maxDate={new Date()}
                            currentDate={new Date()}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            readOnly={this.props.readOnly}
                            readOnlyValue={this.state.employeeRequest.defaultUserTeamDetails && this.state.employeeRequest.defaultUserTeamDetails.name}
                            type='select'
                            label="Default Team"
                            name="userTeamId"
                            isIdAsSelectValue={false}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Default Team..."
                            options={this.props.allTeams && commonUtils.convertObjectToSelectObject(this.props.allTeams)}
                            //options={this.props.allTeams && commonUtils.convertObjectToSelectGroupObject(this.props.allTeams)}
                            onFormError={this.onFormError}
                          />
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            readOnlyValue={(this.state.employeeRequest && this.state.employeeRequest.managerDetails && this.state.employeeRequest.managerDetails.name) || "Nil"}
                            label="Reporting Manager"
                            name="managerId"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Reporting Manager..."
                            options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(this.props.allEmployees)}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            readOnly={this.props.readOnly || (this.props.editMode && this.props.allSubRoles && (this.props.allSubRoles.find(role => this.state.employeeRequest.userRole === role.id) === undefined))}
                            readOnlyValue={this.state.employeeRequest.userAccessDetails && this.state.employeeRequest.userAccessDetails.name}
                            type='select'
                            label="User Role"
                            name="userRole"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="User Role..."
                            options={this.props.allSubRoles && commonUtils.convertObjectToSelectObject(this.props.allSubRoles)}
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card pb-0 mb-0">
                  <div className="card-header pt-0 pb-1">
                    <span className="text-color-gray-aaa"><i className="fa fa-info-circle pr-1"></i>Additional details</span>
                    <div className="card-options">
                      <button className="link card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></button>
                    </div>
                  </div>
                  <div className="col-12 row">
                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Department Lead"
                            readOnlyValue={(this.state.employeeRequest && this.state.employeeRequest.departmentLeadDetails && this.state.employeeRequest.departmentLeadDetails.name) || "Nil"}
                            name="deptLeadId"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Department Lead..."
                            options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(this.props.allEmployees)}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly} type='select'
                            readOnlyValue={(this.state.employeeRequest && this.state.employeeRequest.lobHeadDetails && this.state.employeeRequest.lobHeadDetails.name) || "Nil"}
                            label="LOB Head"
                            name="lobHeadId"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="LOB Head..."
                            options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(this.props.allEmployees)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <CustomInput
                            readOnly={this.props.readOnly}
                            type='select'
                            label="Service Lines"
                            name="serviceLines"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Service Lines..."
                            multi={true}
                            options={this.props.projectAppConfig && workItemUtils.getServiceLines(this.props.projectAppConfig)}
                            onFormError={this.onFormError}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <CustomInput
                            readOnly={this.props.readOnly}
                            type='select'
                            label="Service Category"
                            name="serviceCategory"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Service Category..."
                            isAppConfig={true} createConfig={this.props.createConfig}
                            onFormError={this.onFormError} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Primary Practice"
                            name="department"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Primary Practice..."
                            isAppConfig={true} createConfig={this.props.createConfig}
                            onFormError={this.onFormError} />
                        </div>
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly} type='select'
                            label="Division"
                            name="division"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Division..."
                            isAppConfig={true} createConfig={this.props.createConfig}
                            onFormError={this.onFormError} />
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Secondary Practice"
                            name="subDepartment"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Secondary Practice..."
                            isAppConfig={true} createConfig={this.props.createConfig}
                            onFormError={this.onFormError}
                          />
                        </div>
                        <div className="col-6">
                          <CustomInput
                            readOnly={this.props.readOnly}
                            type='select'
                            label="Primary Client"
                            name="primaryClient"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Primary Client..."
                            options={this.props.allProjectTeamAccounts && commonUtils.getAccountOptions(this.props.allProjectTeamAccounts)}
                            onFormError={this.onFormError}
                          />
                        </div>

                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <div className="col-12">
                          <CustomInput readOnly={this.props.readOnly}
                            readOnlyValue={this.state.employeeRequest.additionalUserTeamDetails && this.state.employeeRequest.additionalUserTeamDetails.map(item => item.name).join(',')}
                            type='select'
                            label="Additional Team Access"
                            name="additionalUserTeamIds"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Additional Team Access..."
                            options={this.props.allTeams && commonUtils.convertObjectToSelectObject(this.props.allTeams)}
                            //options={this.props.allTeams && commonUtils.convertObjectToSelectGroupObject(this.props.allTeams)}
                            onFormError={this.onFormError}
                            multi={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Band"
                            name="band"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Band..."
                            isAppConfig={true}
                            createConfig={this.props.createConfig}
                            onFormError={this.onFormError} />
                        </div>

                        <div className="col-6">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Grade"
                            name="grade"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Grade..."
                            isAppConfig={true} createConfig={this.props.createConfig}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Domains"
                            name="domains"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Domains..."
                            isAppConfig={true}
                            createConfig={this.props.createConfig}
                            onFormError={this.onFormError}
                            multi={true}
                          /></div>

                      </div>

                      <div className="row">
                        <div className="col-12">
                          <CustomInput readOnly={this.props.readOnly}
                            type='select'
                            label="Certifications"
                            name="certifications"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.employeeRequest}
                            onChange={this.onChange}
                            placeholder="Certifications..."
                            isAppConfig={true}
                            createConfig={this.props.createConfig}
                            onFormError={this.onFormError}
                            multi={true}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="card pb-0 mb-0">
                  <div className="card-header pt-0 pb-1">
                    <span className="text-color-gray-aaa"><i className="fa fa-info-circle pr-1"></i>Skills</span>
                    <div className="card-options">
                      <button className="link card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></button>
                    </div>
                  </div>
                  <div className="col-12 row">
                    <EmployeeSelectionSkills
                      name="skills"
                      onChange={this.onChange}
                      readOnly={this.props.readOnly}
                      createConfig={this.props.createConfig}
                      fieldConfig={this.props.fieldConfig}
                      mappingObject={this.state.employeeRequest}
                      onFormError={this.onFormError}
                    />
                  </div>
                </div>

              </div>

            </div>
            {!this.props.readOnly &&
              <SidebarFooter
                handleSubmit={this.handleSubmit}
                editMode={this.props.editMode}
                initializeRequest={this.initializeRequest}
                isProcessing={this.props.isProcessing}
                hasFormError={this.state.hasFormError}
                onResetFormError={this.onResetFormError}
                effectiveUpdateDateEnabled={true}
                onEffectiveDateDateChange={this.onEffectiveDateDateChange}
                mappingObject={this.state.employeeRequest}
              />
            }
          </>
        }
      </>
    );
  }
}

class EmployeeSelectionSkills extends Component {

  constructor() {
    super();
    this.state = {
      formErrors: {},
      skills: {},
      hasFormError: false,

    }
  }
  componentDidMount() {
    let employeeSkills = this.props.mappingObject && this.props.mappingObject.skills;
    this.setState({
      skills: employeeSkills ? employeeSkills : this.state.skills
    });
  }
  onChange = (name, value) => {
    this.setState({
      skills: {
        ...this.state.skills,
        [name]: value
      }
    }, () => {
      this.props.onChange(this.props.name, this.state.skills);
    });

  }

  render() {
    return (
      <>
        <div className="col-12 row">
          <div className="col-6">
            <div className="col-12">
              <div className="row">
                <SkillSelection
                  name="primarySkill"
                  label="Primary Skill"
                  onChange={this.onChange}
                  readOnly={this.props.readOnly}
                  createConfig={this.props.createConfig}
                  mappingObject={this.props.mappingObject}
                  onFormError={this.props.onFormError}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <SkillSelection
                  name="secondarySkill"
                  label="Secondary Skill"
                  onChange={this.onChange}
                  readOnly={this.props.readOnly}
                  createConfig={this.props.createConfig}
                  mappingObject={this.props.mappingObject}
                  onFormError={this.props.onFormError}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <SkillSelection
                  name="tertiarySkill"
                  label="Tertiary Skill"
                  onChange={this.onChange}
                  readOnly={this.props.readOnly}
                  createConfig={this.props.createConfig}
                  mappingObject={this.props.mappingObject}
                  onFormError={this.props.onFormError}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="col-12">
              <SkillsSelection
                name="additionalSkills"
                label="Additional Skills"
                onChange={this.onChange}
                readOnly={this.props.readOnly}
                createConfig={this.props.createConfig}
                mappingObject={this.props.mappingObject}
                onFormError={this.props.onFormError}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

class SkillSelection extends Component {
  constructor() {
    super();
    this.state = {
      formErrors: {},
      skill: {
        level: 'BASIC'
      },
      hasFormError: false,

    }
  }
  componentDidMount() {
    let employeeSkills = this.props.mappingObject && this.props.mappingObject.skills && this.props.mappingObject.skills[this.props.name];
    this.setState({
      skill: (!this.props.multi && employeeSkills) ? employeeSkills : this.state.skill
    });
  }

  onFieldValueChange = (name, value) => {
    this.setState({
      skill: {
        ...this.state.skill,
        [name]: value
      }
    }, () => {
      this.props.onChange(this.props.name, this.state.skill);
    });
  }
  render() {
    return (
      <>

        <div className="col-6">
          <CustomInput readOnly={this.props.readOnly}
            type='select'
            label={this.props.label}
            name="name"
            fieldConfig={this.props.fieldConfig}
            mappingObject={this.state.skill}
            onChange={this.onFieldValueChange}
            placeholder="Skill..."
            isAppConfig={true}
            createConfig={this.props.createConfig}
            configName={"skills"}
            onFormError={this.props.onFormError}
          />
        </div>
        <div className="col-4">
          <CustomInput readOnly={this.props.readOnly}
            type='select'
            label="Level"
            name="level"
            disableClearable={true}
            fieldConfig={this.props.fieldConfig}
            mappingObject={this.state.skill}
            onChange={this.onFieldValueChange}
            placeholder="Level"
            options={[{ value: 'BASIC', label: 'Basic' }, { value: 'INTERMEDIATE', label: 'Intermediate' }, { value: 'ADVANCE', label: 'Advance' }, { value: 'EXPERT', label: 'Expert' }]}
            onFormError={this.props.onFormError}
          />
        </div>

      </>
    )
  }
}

class SkillsSelection extends Component {
  constructor() {
    super();
    this.state = {
      formErrors: {},
      skill: null,
      skills: [],
      hasFormError: false,

    }
  }
  componentDidMount() {
    let employeeSkills = this.props.mappingObject && this.props.mappingObject.skills && this.props.mappingObject.skills[this.props.name];
    this.setState({
      skills: employeeSkills ? employeeSkills : this.state.skills,
    });
  }

  onChange = (name, skillValue) => {
    this.setState({
      skill: skillValue
    })
  }

  addSkillToList = () => {
    if (this.state.skill && this.state.skill.name !== null && this.state.skill.name !== '') {
      if (!(this.state.skills && this.state.skills.find((skill) => skill.name === this.state.skill.name))) {
        this.setState({
          skills: [...this.state.skills, this.state.skill]
        }, () => {
          this.props.onChange(this.props.name, this.state.skills);
        });
      }
    }
  }


  onRemoveEmployeeSkill = (tobeRemovedName) => {
    let filterdSkills = this.state.skills && this.state.skills.filter(skill => skill.name !== tobeRemovedName);
    this.setState({
      skills: filterdSkills
    }, () => {
      this.props.onChange(this.props.name, this.state.skills);
    });
  }

  render() {
    return (
      <>
        <div className="row">
          {!this.props.readOnly &&
            <>
              <SkillSelection
                name="additionalSkills"
                label="Additional Skills"
                onChange={this.onChange}
                multi={true}
                readOnly={this.props.readOnly}
                createConfig={this.props.createConfig}
                mappingObject={this.props.mappingObject}
              />
              <div className="col-2 pt-4">
                <button onClick={() => this.addSkillToList()} className="btn btn-primary btn-sm">Add</button>
              </div>
            </>
          }
          {this.props.readOnly &&
            <label className="form-label text-muted">{this.props.label}</label>
          }
        </div>

        <div className="col-12 row">
          {this.state.skills && this.state.skills.length > 0 &&
            <div className="table-responsive p-0">
              <table className="table table-hover table-vcenter table-striped">
                <thead>
                  <tr>
                    <th>Sl No</th>
                    <th>Skill</th>
                    <th>Level</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.skills && this.state.skills.map((skill, index) =>
                    <tr key={skill.name}>
                      <td>{index + 1}</td>
                      <td>{skill.name}</td>
                      <td>{skill.level}</td>
                      <td>
                        {!this.props.readOnly &&
                          <button onClick={() => this.onRemoveEmployeeSkill(skill.name)} className="link pl-2" title="Remove">
                            <i className="dropdown-icon fa fa fa-times colorred"></i>
                          </button>
                        }
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
            </div>
          }
          {!this.state.skills || this.state.skills.length === 0 &&
            <p className="fsize9">No Skills Added</p>
          }
        </div>
      </>
    )
  }
}
export default CreateEmployeeSidebarSection;